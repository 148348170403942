import './App.css';
import { Button, GradationText, Blink, Rotator } from "re-geo";
import rd from './rd1.png';
import barbiekelsey from './barbie-kels.jpg'
import barbiejess from './barbie(1).jpg'
import barbiedonald from './barbie(2).jpg'
import barbiearis from './barbie(3).jpg'
import barbiemark from './barbie(4).jpg'
import barbiemac from './barbie(5).jpg'
import barbiekaty from './barbie(6).jpg'
import barbieaustin from './barbie(7).jpg'
import barbiedaniel from './barbie(8).jpg'
import barbiemarissa from './barbie(9).jpg'
import barbiesterling from './barbie(10).jpg'
import barbiehannah from './barbie(11).jpg'
import barbiemariel from './barbie(12).jpg'
import barbiegrant from './barbie(13).jpg'
import barbiemichael from './barbie(14).jpg'
import barbiecayla from './barbie(15).jpg'
import barbieclaire from './barbie(16).jpg'
import barbieamanda from './barbie(17).jpg'
import barbiejooyung from './barbie(32).jpg'
import barbiesean from './barbie(19).jpg'
import baribiecloyd from './barbie(20).jpg'
import barbieadi from './barbie(22).jpg'
import barbiematthew from './barbie(23).jpg'
import barbiefranz from './barbie(24).jpg'
import barbiekolby from './barbie(25).jpg'
import barbiecasey from './barbie(26).jpg'
import barbieowen from './barbie(27).jpg'
import barbietrevor from './barbie(28).jpg'
import barbienima from './barbie(29).jpg'
import barbiezach from './barbie(30).jpg'
import barbiejay from './barbie(31).jpg'
import barbiejordan from './barbie(34).jpg'
import barbiepablo from './barbie(35).jpg'



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

function App() {

  return (
    <Router>
    <div className='header'>
      <ul className='flex mv2 nav'  style={{ maxWidth: '1400px', marginTop: 0, marginBottom: '20px'}}>
        <li className='mr4'>
          <Link to="/">Home</Link>
        </li>
        <li className='mr4'>
          <Link to="/agenda">Agenda</Link>
        </li>
        <li>
          <Link to="/fun-stuff">Fun stuff</Link>
        </li>
      </ul>
      <Switch>
        <Route exact path="/">
        <HomePage />
        </Route>
        <Route path="/agenda">
          <About />
        </Route>
        <Route path="/fun-stuff">
          <Fun />
        </Route>
      </Switch>
    </div>
  </Router>
  );
}

export default App;

const Fun = () => (
  <div className='w-90-l center'>
    <div class="grid-container">

      <div class="grid-item">
        <img src={barbiemark} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiekelsey} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiesterling} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiedaniel} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiedonald} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiemichael} className='imgs' />
      </div>
      
      <div class="grid-item">
        <img src={barbiearis} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiekolby} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiezach} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiejooyung} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiematthew} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiefranz} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiejordan} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiemariel} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiemac} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiekaty} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbieowen} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbieaustin} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiemarissa} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbietrevor} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiejay} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiehannah} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiejess} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiegrant} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbieclaire} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbienima} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiecayla} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiepablo} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbieamanda} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiesean} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={baribiecloyd} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbieadi} className='imgs' />
      </div>
      <div class="grid-item">
        <img src={barbiecasey} className='imgs' />
      </div>
    </div>


    <footer className='footer'></footer>

  </div>
)
const HomePage = () => {
  const history = useHistory();

return (
  <div className="container">
    <img src={rd} className='img1' />

    <div style={{ maxWidth: '90%', margin: 'auto'}} className='parent-box'>

      <div className='box'>

      <div className='cast'>
      <h3 className='starring'>Starring:</h3>

        <marquee className='marquee' direction='up' >
        <p>@mark</p>
        <p>@kelsey</p>
        <p>@kolby</p>
        <p>@owen</p>
        <p>@mac</p>
        <p>@mariel</p>
        <p>@katy</p>
        <p>@grant cloyd</p>
        <p>@matthew burke</p>
        <p>@jordan</p>
        <p>@sterling</p>
        <p>@daniel</p>
        <p>@cayla</p>
        <p>@johnny</p>
        <p>@grant ho</p>
        <p>@hannah</p>
        <p>@aris</p>
        <p>@jay</p>
        <p>@sean</p>
        <p>@zach</p>
        <p>@franz</p>
        <p>@jess</p>
        <p>@nima</p>
        <p>@michael</p>
        <p>@casey</p>
        <p>@jooyung</p>
        <p>@donald</p>
        <p>@trevor</p>
        <p>@amanda</p>
        <p>@marissa</p>
        <p>@amanda</p>
        <p>@austin</p>
        <p>@claire</p>
        </marquee>
      </div>
</div>
    </div>
    <footer className='footer'></footer>
 </div>
)
}


const About = () => (
  <div className=' about w-90 center mt4 bold light-pink bg-white br4'>

    <GradationText
      fontSize='28'
      isAnimate
      colorArray={[
        '#ffc2cd',
        '#ff93ac',
        '#ff6289',
        '#fc3468',
        'white'
      ]}
    >
    here's the f*cking agenda
    </GradationText>
    <div className='starz db mt3 hotpink'></div>
      <hr/>
      <p className='b underline'>Monday, October 2 -- Arrival ✈️ </p>
      <ul className='mb3'>
        <li className='mb2'>
        Head to your place of stay— and if you're in office hours— over to the Birmingham Landing office!
        </li>
        <li className='mb2'>
        Landing Office: 17 20th Street North, Birmingham, AL 35023
        </li>
        <li className='mb2'>1st floor of John Hand (there's a big ole Landing sign above the main doors). </li>
        <li>We will be sitting on/ working at the seats in the front, and the seats in the back!</li>
      </ul>

      <p className='b underline'>Tuesday, October 3</p>
      <ul className='mb3'>
      <li className='mb3'>🥯 Mark is providing chikin biscuitsss.</li>
      <li className='mb3'>🎤 **10:00am**: NedTalks - lightning talks from the crowd in Sols (downstairs)</li>
      <li className='mb3'>Eat lunch with someone, meet the folks you work with!</li>

      <li className='mb3'>🍹 **5:00-6:00pm**: Happy Hour with Bill. - 20th floor of the John Hand Clerb.</li>
      </ul>

      <p className='b underline'>Wednesday, October 4</p>
      <ul className='mb3'>
        <li className='mb3'>⛳ **10:00am-12:00pm**: Top Golf with the team. You'll probably want to share ubers or ride with drivers!</li>
        <li className='mb3'> Shadow with the team you work with most! Get a coffee with someone!</li>
        <li className='mb3'> Free evening! Go get dinner, a drink, whatever you want.</li>

      </ul>

      <p className='b underline'>Thursday, October 5</p>
      <ul>
        <li className='mb3'>📦 **3:00pm- Raffle??</li>
        <li className='mb3'>🎮 **4:00 - ? **: Game Night -- Location: 3rd Floor John Hand</li>
      </ul>

      <p className='b underline'>Friday, October 6 -- Goodbye ✈️ </p>
      <ul className=''>
       <li>Return your access card, plz! </li>
      </ul>

  </div>

)
